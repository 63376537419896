<template>
  <div :class="'cus-header view mb-3 ' + classes.div">
    <img :src="getImage(imgUrl)" :class="'img-fluid ' + classes.img" />
    <div class="mask flex-center">
      <div class="container">
        <span :class="'texto ' + classes.texto">
          {{ texto }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    imgUrl: String,
    texto: String,
    classes: Object,
  },
  methods: {
    getImage(fileName) {
      return require("../assets/images/headers/" + fileName);
    },
  },
};
</script>

<style scoped lang="scss">
.view {
  position: relative;
  overflow: hidden;
  cursor: default;
}

.view img {
  position: relative;
  display: block;
}

.view .mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-attachment: fixed;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.mask {
  background-attachment: fixed;
  display: table-cell;
}

.texto {
  color: white;
  align-self: flex-start;
  text-align: left;
  font-size: 2em;
  align-self: center;
}
.flex-center {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0;
}
.pointer {
  cursor: pointer;
}

@media (max-width: 425px) {
  img {height: 15vh !important;}

  .texto {
    font-size: 1.5rem;
  }
}
</style>
